html,
body {
  width: 100%;
  // height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: var(--color-bg-1);
}

.chart-wrapper {
  .bizcharts-tooltip {
    background: linear-gradient(
      304.17deg,
      rgb(253 254 255 / 60%) -6.04%,
      rgb(244 247 252 / 60%) 85.2%
    ) !important;
    border-radius: 6px;
    backdrop-filter: blur(10px);
    padding: 8px !important;
    width: 180px !important;
    opacity: 1 !important;
  }
}

body[arco-theme='dark'] {
  .chart-wrapper {
    .bizcharts-tooltip {
      background: linear-gradient(
        304.17deg,
        rgba(90, 92, 95, 60%) -6.04%,
        rgba(87, 87, 87, 60%) 85.2%
      ) !important;
      backdrop-filter: blur(10px);
      border-radius: 6px;
      box-shadow: none !important;
    }
  }
}

.cardCustomHover {
  margin: 10px;
  transition-property: all;
}

.cardCustomHover:hover {
  transform: translateY(-4px);
}

.bmpui-ui-watermark {
  display: none;
}

.bmpui-ui-controlbar {
  font-family: 'Titillium Web', sans-serif !important;
}

.bmpui-ui-buffering-overlay {
  background-color: rgba(255, 0, 0, 22.1%) !important;
}

.bmpui-seekbar-playbackposition {
  background-color: #e10600;
}

.bmpui-seekbar-playbackposition {
  background-color: #e10600 !important;
}

.bmpui-seekbar-playbackposition-marker {
  background-color: #e10600 !important;
  border: 0.1em solid #e10600 !important;
}

.bmpui-ui-buffering-overlay-indicator {
  filter: hue-rotate(160deg) saturate(300%) brightness(100%) !important;
}

.bmpui-ui-selectbox {
  color: #e10600 !important;
}

.bmpui-ui-settingstogglebutton {
  filter: hue-rotate(160deg) saturate(300%) brightness(100%) !important;
}

.bmpui-ui-fullscreentogglebutton {
  filter: hue-rotate(160deg) saturate(300%) brightness(100%) !important;
}

// .bmpui-seekbar-playbackposition-markerr	{
//   background-color: red;
// }
// .bmpui-ui-volumeslider 	{
//   background-color: red;
//   color: red;
// }
